import React from "react";
import appStyles from "../../App.module.css";

type Props = {
  icon: string;
  iconWidth?: number;
};

const SvgIcon: React.FC<Props> = (props: Props) => {
  const { icon, iconWidth } = props;

  return (
    <svg className={`${appStyles.svgIcon}`} style={{ width: `${iconWidth}` }}>
      <use href={`#tii-pp-${icon}`}></use>
    </svg>
  );
};

export default SvgIcon;
