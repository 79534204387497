import { createContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import QuoteApi from "../api/quote";
import appConstants, {
  convertDateToApiFormat,
  defaultPackageLevels,
  formatAmount,
  pathToStep,
  stepToPath,
} from "../config/app-constants";
import {
  IDataRedirection,
  ISingleTripQuoteContext,
  ISingleTripQuoteData,
  SelectOption,
} from "../config/interfaces";
import StorageService from "../services/StorageService";
import Header from "./common/Header";
import LoadingIndicator from "./common/LoadingIndicator";
import Step from "./Step";
import QuoteForm from "./steps/QuoteForm";

export const SingleTripQuoteContext = createContext<ISingleTripQuoteContext | null>(null);

const INITIAL_QUOTE = {
  host_country: null,
  host_country_state: "VIC",
  currency: {
    label: "AUD",
    value: "AUD",
  },
  destinations: [],
  start_date: null,
  end_date: null,
  main_applicant: null,
  other_applicants: [],
  all_applicants: [],
  packages: null,
  excess_fee: appConstants.excessFee2,
  affiliate_id: "",
  rental_vehicle_start_Date: null,
  rental_vehicle_end_Date: null,
};

const TripQuote = ({ redirection }: IDataRedirection) => {
  const storageSvc = new StorageService(redirection);
  const totalSteps: number = 4;
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState<number>(storageSvc.getCurrentStep() || 0);
  const [quoteData, setQuoteData] = useState<ISingleTripQuoteData>(
    () => storageSvc.getQuoteData() || INITIAL_QUOTE
  );
  const [quoteResult, setQuoteResult] = useState<any>(storageSvc.getQuoteResult() || null);
  const [order, setOrder] = useState<any>(storageSvc.getOrder() || null);
  const [loader, setLoader] = useState<
    "master_data" | "quote" | "packages" | "order" | "payment" | "policy" | "initial" | null
  >("master_data");
  const [destinations, setDestinations] = useState<Array<any>>(
    storageSvc.getAllDestinationCountries() || []
  );
  const [hostCountries, setHostCountries] = useState<Array<any>>(
    storageSvc.getAllHostCountries() || []
  );
  const [packages, setPackages] = useState<Array<any>>(storageSvc.getPackages() || []);
  const [packageAmount, setPackageAmount] = useState<any>(storageSvc.getPackageAmount() || null);
  const location = useLocation();

  useEffect(() => {
    if (redirection || (!destinations.length && !location.pathname.split("/")?.pop()?.length)) {
      init();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const init = async () => {
    try {
      setLoader("initial");
      let _quoteData: ISingleTripQuoteData = { ...quoteData };

      _quoteData.excess_fee = appConstants.excessFee2;
      const queryString = window.location.search;
      // affiliated values always available either user pass or not
      const urlParams = new URLSearchParams(queryString);

      _quoteData.affiliate_id = urlParams.get("afid") || appConstants.affiliatedId!;

      // getting query params if destinations and mainApplicant found then skip step 1
      const startDateParams = urlParams.get("startDate");
      const endDateParams = urlParams.get("endDate");

      if (startDateParams) {
        _quoteData.start_date = new Date(startDateParams) || null;
        _quoteData.rental_vehicle_start_Date = new Date(startDateParams) || null;
      }
      if (endDateParams) {
        _quoteData.end_date = new Date(endDateParams) || null;
        _quoteData.rental_vehicle_end_Date = new Date(endDateParams) || null;
      }

      const mainApplicantParam = urlParams.get("mainApplicant");
      if (mainApplicantParam) {
        _quoteData.main_applicant = new Date(mainApplicantParam) || null;
      }

      const _destinationsParams = urlParams.get("destinations");
      if (_destinationsParams) {
        _quoteData.destinations = JSON.parse(decodeURIComponent(_destinationsParams)) || [];
      }
      const _otherApplicantsParams = decodeURIComponent(urlParams.get("otherApplicants") || "");

      if (_otherApplicantsParams) {
        _quoteData.other_applicants =
          JSON.parse(_otherApplicantsParams).map(
            (_otherApplicant: Date) => new Date(_otherApplicant)
          ) || [];
      }

      const hcResponse: any = await QuoteApi.getHostCountries(appConstants.productId!);
      if (hcResponse?.data?.length === 1) {
        _quoteData.host_country = hcResponse?.data[0];
      }
      const dcResponse: any = await QuoteApi.getSTDestinations(appConstants.productId!);
      const filteredDestinations = dcResponse?.data
        .sort((a: any, b: any) => a.name.localeCompare(b.name))
        .filter((item: any) => !item.is_disabled);

      if (_quoteData?.main_applicant && _quoteData?.destinations.length > 0) {
        const _packages = await getPackages(
          _quoteData,
          hcResponse?.data[0].id,
          hcResponse?.data[0]?.states[0]?.id
        );

        const _discount = await getDiscountAmounts(_quoteData, _packages, true);

        _quoteData = await updateQuoteDataPackages(_packages, _quoteData);

        const _quoteResult = await getQuote(_quoteData);

        setQuoteData(_quoteData);
        setPackages(() => _packages);
        setQuoteResult(_quoteResult);
        setPackageAmount(_discount);
        setHostCountries(hcResponse?.data || []);
        setDestinations(() => filteredDestinations);
        navigate("/quote-details");

        return;
      }

      setHostCountries(hcResponse?.data || []);
      setDestinations(() => filteredDestinations);
      setQuoteData(_quoteData);
    } catch (e) {
      console.log(e);
    } finally {
      setLoader(null);
    }
  };

  useEffect(() => {
    storageSvc.setQuoteData(quoteData);
  }, [quoteData]);

  useEffect(() => {
    storageSvc.setQuoteResult(quoteResult);
  }, [quoteResult]);

  useEffect(() => {
    storageSvc.setPackageAmount(packageAmount);
  }, [packageAmount]);

  useEffect(() => {
    storageSvc.setPackages(packages);
  }, [packages]);

  useEffect(() => {
    storageSvc.setCurrentStep(currentStep);
  }, [currentStep]);

  useEffect(() => {
    storageSvc.setOrder(order);
  }, [order]);

  useEffect(() => {
    storageSvc.setAllHostCountires(hostCountries);
  }, [hostCountries]);

  useEffect(() => {
    storageSvc.setAllDestinationCountries(destinations);
  }, [destinations]);

  useEffect(() => {
    const pathName: any = location.pathname.split("/").pop();
    const step = pathToStep[pathName] ?? 0;
    setCurrentStep(() => step);
  }, [location.pathname]);

  const updateStep = (step: number) => {
    const path = stepToPath[step] || "/";
    navigate(path);
  };

  const next = () => {
    if (currentStep < totalSteps) {
      setCurrentStep(currentStep + 1);
      updateStep(currentStep + 1);
    }
  };

  const prev = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
      updateStep(currentStep - 1);
    }
  };

  const getPackages = async (
    _quoteData: ISingleTripQuoteData,
    countryCode: number,
    stateCode: number
  ) => {
    const pkgResponse: any = await QuoteApi.getHostCountryPackages(
      appConstants.productId!,
      countryCode,
      stateCode
    );

    if (pkgResponse.error) {
      throw pkgResponse;
    }

    return (
      pkgResponse?.data?.map((pkg: any) => {
        if (pkg?.levels) {
          const isRequired: any = pkg?.validations?.find((elem: any) => {
            return elem?.product_package_validation_type?.name === "Is Required";
          });

          const defaultOption: Array<any> = [];

          if (!isRequired && pkg?.levels?.length > 1) {
            defaultOption.push({
              id: 0,
              level: "Not included",
            });
          }
          pkg.levels = defaultOption.concat(
            (pkg.alias === "rental_vehicle_damage_package"
              ? pkg?.levels?.filter((_option: any) => _option.type !== "day")
              : pkg?.levels
            ).map((option: any) => {
              switch (option.type) {
                case "package":
                  return {
                    ...option,
                    level: `Package ${option.level}`,
                  };
                case "day":
                  return {
                    ...option,
                    level: `${option.level} Days`,
                  };
                default:
                  const amount = formatAmount(
                    Number(option?.level) || 0,
                    quoteData?.host_country?.code || "AU",
                    quoteData?.currency?.value || "AUD",
                    0,
                    0
                  );
                  return { ...option, level: amount };
              }
            })
          );
        }

        return pkg;
      }) || []
    );
  };

  const updateQuoteDataPackages = async (
    _packages: Array<any>,
    _quoteData: ISingleTripQuoteData
  ) => {
    if (!Object.keys(_quoteData.packages || {}).length) {
      const _selectedPackages: any = {};

      _packages.forEach((pkg: any) => {
        const isRequired: any = pkg?.validations?.find((elem: any) => {
          return elem?.product_package_validation_type?.name === "Is Required";
        });
        const defaultLevel = defaultPackageLevels[pkg.alias];
        if (pkg?.levels?.length) {
          let selectedLevel = Object.keys(defaultPackageLevels).includes(pkg?.alias)
            ? pkg?.levels?.find(
                (_level: any) =>
                  _level.value == defaultLevel.value && _level.type == defaultLevel.type
              )
            : null;

          if (isRequired || selectedLevel) {
            _selectedPackages[pkg.alias] = {
              package: pkg,
              level: selectedLevel ? selectedLevel : pkg.levels[0],
              defaultLevel: selectedLevel ? selectedLevel : pkg.levels[0],
            };
          }
        }
      });

      _quoteData.packages = _selectedPackages;
    }
    return _quoteData;
  };

  const getDiscountAmounts = async (
    _quoteData: ISingleTripQuoteData,
    _packages: any,
    isDefault?: boolean
  ) => {
    let _selectedPackages: any = [];

    if (isDefault) {
      _packages.forEach((pkg: any) => {
        const isRequired: any = pkg?.validations?.find((elem: any) => {
          return elem?.product_package_validation_type?.name === "Is Required";
        });

        const defaultLevel = defaultPackageLevels[pkg.alias];

        if (pkg?.levels?.length) {
          let selectedLevel = Object.keys(defaultPackageLevels).includes(pkg?.alias)
            ? pkg?.levels?.find(
                (_level: any) =>
                  _level.value == defaultLevel.value && _level.type == defaultLevel.type
              )
            : pkg.levels[0];

          if (pkg.alias === "rental_vehicle_damage_package") {
            _selectedPackages.push({
              id: pkg.id,
              conditions: [
                {
                  start_date: convertDateToApiFormat(_quoteData.start_date),
                  end_date: convertDateToApiFormat(_quoteData.end_date),
                },
              ],
            });
          }

          if (isRequired || (selectedLevel && pkg.alias !== "rental_vehicle_damage_package")) {
            _selectedPackages.push({
              id: pkg.id,
              levels: [{ id: selectedLevel.id }],
            });
          }
        }
      });

      if (_quoteData?.packages !== null) {
        Object.keys(_quoteData?.packages).forEach((item: any) => {
          _selectedPackages.find((_item: any) => {
            if (
              _item.id === _quoteData.packages[item].package.id &&
              item === "rental_vehicle_damage_package"
            ) {
              _item.conditions = [
                {
                  start_date: convertDateToApiFormat(_quoteData.rental_vehicle_start_Date),
                  end_date: convertDateToApiFormat(_quoteData.rental_vehicle_end_Date),
                },
                { level: _quoteData.packages[item].level.value },
              ];
            }
            if (
              _item.id === _quoteData.packages[item].package.id &&
              item !== "rental_vehicle_damage_package"
            ) {
              _item.levels = [{ id: _quoteData.packages[item].level.id }];
            }
          });
        });
      }
    } else {
      Object.keys(_quoteData.packages).forEach((pkg: any) => {
        if (pkg === "rental_vehicle_damage_package") {
          _selectedPackages.push({
            id: _quoteData.packages[pkg].package.id,
            conditions: [
              {
                start_date: convertDateToApiFormat(_quoteData.rental_vehicle_start_Date),
                end_date: convertDateToApiFormat(_quoteData.rental_vehicle_end_Date),
              },
              { level: _quoteData.packages[pkg]?.level.value },
            ],
          });
        } else {
          _selectedPackages.push({
            id: _quoteData.packages[pkg].package.id,
            levels: [{ id: _quoteData.packages[pkg]?.level.id }],
          });
        }
      });
    }

    const apiData: any = {
      affiliate: _quoteData.affiliate_id,
      applicants: [
        {
          is_main: true,
          dob: convertDateToApiFormat(_quoteData.main_applicant),
          trip_cost: 1,
        },
      ].concat(
        _quoteData.other_applicants
          ?.filter((dob) => dob != null)
          .map((dob) => ({
            is_main: false,
            dob: convertDateToApiFormat(dob),
            trip_cost: 1,
          })) || []
      ),
      currency: quoteData.currency?.value,
      deductibles: {
        excess_fee: _quoteData.excess_fee,
      },
      deposit_date: convertDateToApiFormat(new Date()),
      discount: { percentage: 0 },
      end_date: convertDateToApiFormat(_quoteData.end_date),
      host_country: quoteData?.host_country?.code || "AU",
      host_country_state: quoteData.host_country_state || "NSW",
      start_date: convertDateToApiFormat(_quoteData.start_date),
      product: appConstants.productId,
      packages: _selectedPackages,
    };

    apiData.destinations = _quoteData.destinations?.map((dest: SelectOption) => dest?.code);
    const quoteResponse: any = await QuoteApi.packageAmount(apiData);

    if (quoteResponse.error) {
      throw quoteResponse;
    }

    return quoteResponse?.data || null;
  };

  const getQuote = async (_quoteData: ISingleTripQuoteData, useDefaultLevels: boolean = false) => {
    const apiData: any = {
      start_date: convertDateToApiFormat(_quoteData.start_date),
      end_date: convertDateToApiFormat(_quoteData.end_date),
      product: appConstants.productId,
      host_country: quoteData?.host_country?.code || "AU",
      currency: quoteData.currency?.value,
      packages: Object.values(_quoteData.packages || {})
        ?.filter((pkg: any) => (!useDefaultLevels ? pkg.level?.id : pkg.defaultLevel?.id))
        ?.map((pkg: any) => {
          if (useDefaultLevels) {
            let _pkgObj: any = {
              id: pkg.package.id,
              levels: [
                {
                  id: pkg.defaultLevel.id,
                },
              ],
            };
            if (pkg.package.alias === "rental_vehicle_damage_package") {
              _pkgObj = {
                id: pkg.package.id,
                conditions: [
                  {
                    start_date: convertDateToApiFormat(_quoteData.rental_vehicle_start_Date),
                    end_date: convertDateToApiFormat(_quoteData.rental_vehicle_end_Date),
                  },
                  { level: pkg.defaultLevel.value },
                ],
              };
            }
            return pkg.defaultLevel.id === 1 // in case of level with id 1 (added locally in updateQuoteDataPackages function, we will only send package id)
              ? pkg.package.id
              : _pkgObj;
          } else {
            let _pkgObj: any = {
              id: pkg.package.id,
              levels: [
                {
                  id: pkg.level.id,
                },
              ],
            };
            if (pkg.package.alias === "rental_vehicle_damage_package") {
              _pkgObj = {
                id: pkg.package.id,
                conditions: [
                  {
                    start_date: convertDateToApiFormat(_quoteData.rental_vehicle_start_Date),
                    end_date: convertDateToApiFormat(_quoteData.rental_vehicle_end_Date),
                  },
                  { level: pkg.level.value },
                ],
              };
            }
            return pkg.level.id === 1 // in case of level with id 1 (added locally in updateQuoteDataPackages function, we will only send package id)
              ? pkg.package.id
              : _pkgObj;
          }
        }),

      applicants: [
        {
          is_main: true,
          dob: convertDateToApiFormat(_quoteData.main_applicant),
          trip_cost: 1,
        },
      ].concat(
        _quoteData.other_applicants
          ?.filter((dob: Date | null) => dob != null)
          .map((dob: Date | null) => {
            return {
              is_main: false,
              dob: convertDateToApiFormat(dob),
              trip_cost: 1,
            };
          })
      ),
      deductibles: {
        excess_fee: _quoteData.excess_fee,
      },
    };

    apiData.destinations = _quoteData.destinations?.map((dest: SelectOption) => {
      return dest?.code;
    });
    const quoteResponse: any = await QuoteApi.getQuickQuote(apiData);

    if (quoteResponse.error) {
      throw quoteResponse;
    }

    quoteResponse.data.details = (quoteResponse?.data?.details || []).map((quote: any) => {
      quote.expanded = false;
      return quote;
    });
    return quoteResponse?.data || null;
  };

  const restart = () => {
    setQuoteResult(null);
    setOrder(null);
    setQuoteData(() => INITIAL_QUOTE);
    setPackages([]);
    setCurrentStep(() => 0);
    setPackageAmount(() => null);
    setDestinations(() => []);
    setHostCountries(() => []);
    storageSvc.clearStorageData();
  };

  return (
    <SingleTripQuoteContext.Provider
      value={{
        currentStep,
        totalSteps,
        next,
        prev,
        quoteData,
        setQuoteData,
        restart,
        quoteResult,
        setQuoteResult,
        order,
        setOrder,
        hostCountries,
        destinations,
        loader,
        setLoader,
        packages,
        setPackages,
        getPackages,
        updateQuoteDataPackages,
        getQuote,
        redirection,
        getDiscountAmounts,
        packageAmount,
        setPackageAmount,
      }}
    >
      {!(redirection && currentStep === 0) ? <Header /> : null}
      {redirection ? <QuoteForm /> : loader === "initial" ? <LoadingIndicator /> : <Step />}
    </SingleTripQuoteContext.Provider>
  );
};

export default TripQuote;
