import { FC, FormEvent, useContext, useEffect, useState } from "react";

import { Elements, PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import appStyles from "../../App.module.css";
import QuoteApi from "../../api/quote";
import appConstants from "../../config/app-constants";
import { ISingleTripQuoteContext } from "../../config/interfaces";
import Stepper from "../Stepper";
import { SingleTripQuoteContext } from "../TripQuote";
import NextButton from "../buttons/NextButton";
import PrevButton from "../buttons/PrevButton";
import Footer from "../common/Footer";
import LoadingIndicator from "../common/LoadingIndicator";
import OrderSummary from "../common/OrderSummary";

interface PaymentFormProps {}

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(appConstants.publishableKey!);

const PaymentForm: FC<PaymentFormProps> = (props: PaymentFormProps) => {
  const { order } = useContext(SingleTripQuoteContext) as ISingleTripQuoteContext;

  const [loading, setLoading] = useState<boolean>(false);
  const [stripeIntentData, setStripeIntentData] = useState<any>(null);

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const init = async () => {
    try {
      setLoading(true);

      const response: any = await QuoteApi.getPaymentIntent(order?.order_number);
      setStripeIntentData(response.data);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <LoadingIndicator />}

      <div className={`${appStyles.mainContainer} ${appStyles.W600} ${appStyles.spaceTop}`}>
        <div className={`${appStyles.quotePaymentWrapper}`}>
          <Stepper />

          <div className={`${appStyles.formBlock} ${appStyles.bfMarginTop}`}>
            {!loading && stripeIntentData && (
              <Elements
                stripe={stripePromise}
                options={{
                  clientSecret: stripeIntentData.client_secret,
                }}
              >
                <PaymentFormElement />
              </Elements>
            )}
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default PaymentForm;

type FormProps = {};

const PaymentFormElement: FC<FormProps> = (props: FormProps) => {
  const [isProcessingPayment, setIsProcessingPayment] = useState(false);
  const [isStripeLoading, setIsStripeLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const { next, prev } = useContext(SingleTripQuoteContext) as ISingleTripQuoteContext;

  const stripe = useStripe();
  const elements = useElements();

  const handlePaymentSubmit = async (event: FormEvent<HTMLFormElement>) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsProcessingPayment(true);

    const { error } = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      redirect: "if_required",
      confirmParams: {
        return_url: "",
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.

    if (error) {
      if (error.type === "card_error" || error.type === "validation_error") {
        setErrorMessage(error.message);
      } else {
        setErrorMessage("An unexpected error occurred.");
      }
      setIsProcessingPayment(false);
      return;
    } else {
      setIsProcessingPayment(false);
      next();
    }
  };

  return (
    <form onSubmit={handlePaymentSubmit}>
      <div className={`${appStyles.mB30}`}>
        <OrderSummary />
      </div>
      <div className={appStyles.pageSubTitle}>
        <h5 className={`${appStyles.colorPrimary} ${appStyles.mB30}`}>Card Information</h5>
      </div>
      <div className={`${appStyles.cardWrap}`}>
        <div className={appStyles.formWrapper}>
          <div className={`${appStyles.formRow} ${appStyles.paymentCardWrapper}`}>
            <PaymentElement
              onReady={() => {
                setIsStripeLoading(false);
              }}
              options={ {
                defaultValues: {
                  billingDetails: {
                    address: {
                      country: 'AU'
                    }
                  }
                }
              }}
            />
          </div>
        </div>
      </div>

      {!isStripeLoading && (
        <div className={appStyles.formRow}>
          <div className={`${appStyles.buttonBlock} ${appStyles.buttonBlockNextPrev}`}>
            <PrevButton onClick={() => prev()} />

            <NextButton
              title={isProcessingPayment ? "Processing" : "Make Payment"}
              type='submit'
              disable={isProcessingPayment || !stripe || !elements}
            />
          </div>
        </div>
      )}

      {errorMessage && (
        <div className={appStyles.formRow}>
          <div className={appStyles.battlefaceServerError}>{errorMessage}</div>
        </div>
      )}
    </form>
  );
};
