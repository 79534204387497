import React from "react";
const CommonSvgIcon: React.FC = () => {
  return (
    <svg height="0" style={{display: "none"}}>
      <symbol xmlns='http://www.w3.org/2000/svg' viewBox='0 0 40 40' fill='none' id="tii-pp-search-icon">
        <path
          d='M49.988,0h0.016v0.007C63.803,0.011,76.298,5.608,85.34,14.652c9.027,9.031,14.619,21.515,14.628,35.303h0.007v0.033v0.04 h-0.007c-0.005,5.557-0.917,10.905-2.594,15.892c-0.281,0.837-0.575,1.641-0.877,2.409v0.007c-1.446,3.66-3.315,7.12-5.547,10.307 l29.082,26.139l0.018,0.016l0.157,0.146l0.011,0.011c1.642,1.563,2.536,3.656,2.649,5.78c0.11,2.1-0.543,4.248-1.979,5.971 l-0.011,0.016l-0.175,0.203l-0.035,0.035l-0.146,0.16l-0.016,0.021c-1.565,1.642-3.654,2.534-5.78,2.646 c-2.097,0.111-4.247-0.54-5.971-1.978l-0.015-0.011l-0.204-0.175l-0.029-0.024L78.761,90.865c-0.88,0.62-1.778,1.209-2.687,1.765 c-1.233,0.755-2.51,1.466-3.813,2.115c-6.699,3.342-14.269,5.222-22.272,5.222v0.007h-0.016v-0.007 c-13.799-0.004-26.296-5.601-35.338-14.645C5.605,76.291,0.016,63.805,0.007,50.021H0v-0.033v-0.016h0.007 c0.004-13.799,5.601-26.296,14.645-35.338C23.683,5.608,36.167,0.016,49.955,0.007V0H49.988L49.988,0z M50.004,11.21v0.007h-0.016 h-0.033V11.21c-10.686,0.007-20.372,4.35-27.384,11.359C15.56,29.578,11.213,39.274,11.21,49.973h0.007v0.016v0.033H11.21 c0.007,10.686,4.347,20.367,11.359,27.381c7.009,7.012,16.705,11.359,27.403,11.361v-0.007h0.016h0.033v0.007 c10.686-0.007,20.368-4.348,27.382-11.359c7.011-7.009,11.358-16.702,11.36-27.4h-0.006v-0.016v-0.033h0.006 c-0.006-10.686-4.35-20.372-11.358-27.384C70.396,15.56,60.703,11.213,50.004,11.21L50.004,11.21z'
          fill='currentColor' />
      </symbol>
      <symbol xmlns='http://www.w3.org/2000/svg' viewBox='0 0 40 40' fill='none' id="tii-pp-location-icon">
        <circle cx='19.9994' cy='15.7143' r='5.71429' fill='white'></circle>
        <path
          d='M20 0C11.5566 0 4.6875 6.86914 4.6875 15.3125C4.6875 18.0152 5.40156 20.6723 6.75242 22.9965C7.06734 23.5384 7.41937 24.0664 7.79891 24.566L19.3347 40H20.6652L32.2011 24.5661C32.5805 24.0664 32.9326 23.5384 33.2476 22.9966C34.5984 20.6723 35.3125 18.0152 35.3125 15.3125C35.3125 6.86914 28.4434 0 20 0ZM20 20.3906C17.1999 20.3906 14.9219 18.1126 14.9219 15.3125C14.9219 12.5124 17.1999 10.2344 20 10.2344C22.8001 10.2344 25.0781 12.5124 25.0781 15.3125C25.0781 18.1126 22.8001 20.3906 20 20.3906Z'
          fill='#1e1e1e'></path>
      </symbol>
      <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 13" fill="none" id="tii-pp-calendar-icon">
        <path
          d="M3.50903 1.25C3.50903 0.851562 3.83716 0.5 4.25903 0.5C4.65747 0.5 5.00903 0.851562 5.00903 1.25V2H8.00903V1.25C8.00903 0.851562 8.33716 0.5 8.75903 0.5C9.15747 0.5 9.50903 0.851562 9.50903 1.25V2H10.634C11.2434 2 11.759 2.51562 11.759 3.125V4.25H1.25903V3.125C1.25903 2.51562 1.75122 2 2.38403 2H3.50903V1.25ZM11.759 11.375C11.759 12.0078 11.2434 12.5 10.634 12.5H2.38403C1.75122 12.5 1.25903 12.0078 1.25903 11.375V5H11.759V11.375Z"
          fill="#02182F" />
      </symbol>
      <symbol xmlns="http://www.w3.org/2000/svg" viewBox='0 0 12 11' fill="none" id="tii-pp-clear-icon">
        <path
          d='M10.6875 9.3125C11.0938 9.6875 11.0938 10.3438 10.6875 10.7188C10.5 10.9062 10.25 11 10 11C9.71875 11 9.46875 10.9062 9.28125 10.7188L6 7.4375L2.6875 10.7188C2.5 10.9062 2.25 11 2 11C1.71875 11 1.46875 10.9062 1.28125 10.7188C0.875 10.3438 0.875 9.6875 1.28125 9.3125L4.5625 6L1.28125 2.71875C0.875 2.34375 0.875 1.6875 1.28125 1.3125C1.65625 0.90625 2.3125 0.90625 2.6875 1.3125L6 4.59375L9.28125 1.3125C9.65625 0.90625 10.3125 0.90625 10.6875 1.3125C11.0938 1.6875 11.0938 2.34375 10.6875 2.71875L7.40625 6.03125L10.6875 9.3125Z'
          fill='#AFAFAF' />
      </symbol>
      <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 13" fill="none" id="tii-pp-clear-border-circle-icon">
        <ellipse cx="6" cy="6.04102" rx="6" ry="5.5" fill="white" />
        <path
          d="M4.10156 4.14258C4.3125 3.93164 4.66406 3.93164 4.875 4.14258L5.97656 5.26758L7.10156 4.14258C7.3125 3.93164 7.66406 3.93164 7.875 4.14258C8.10938 4.37695 8.10938 4.72852 7.875 4.93945L6.77344 6.04102L7.875 7.14258C8.10938 7.37695 8.10938 7.72852 7.875 7.93945C7.66406 8.17383 7.3125 8.17383 7.10156 7.93945L5.97656 6.83789L4.875 7.93945C4.66406 8.17383 4.3125 8.17383 4.10156 7.93945C3.86719 7.72852 3.86719 7.37695 4.10156 7.14258L5.20312 6.04102L4.10156 4.93945C3.86719 4.72852 3.86719 4.37695 4.10156 4.14258ZM12 6.04102C12 9.36914 9.30469 12.041 6 12.041C2.67188 12.041 0 9.36914 0 6.04102C0 2.73633 2.67188 0.0410156 6 0.0410156C9.30469 0.0410156 12 2.73633 12 6.04102ZM6 1.16602C3.30469 1.16602 1.125 3.36914 1.125 6.04102C1.125 8.73633 3.30469 10.916 6 10.916C8.67188 10.916 10.875 8.73633 10.875 6.04102C10.875 3.36914 8.67188 1.16602 6 1.16602Z"
          fill="currentColor" />
      </symbol>
      <symbol xmlns="http://www.w3.org/2000/svg" viewBox='0 0 13 11' fill="none" id="tii-pp-back-arrow-icon">
        <path
          d="M12.5977 5.75C12.5977 6.24219 12.2148 6.625 11.75 6.625H3.35547L6.22656 9.52344C6.58203 9.85156 6.58203 10.4258 6.22656 10.7539C6.0625 10.918 5.84375 11 5.625 11C5.37891 11 5.16016 10.918 4.99609 10.7539L0.621094 6.37891C0.265625 6.05078 0.265625 5.47656 0.621094 5.14844L4.99609 0.773438C5.32422 0.417969 5.89844 0.417969 6.22656 0.773438C6.58203 1.10156 6.58203 1.67578 6.22656 2.00391L3.35547 4.875H11.75C12.2148 4.875 12.5977 5.28516 12.5977 5.75Z"
          fill="currentColor" />
      </symbol>
      <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 9" fill="none" id="tii-pp-forward-arrow-icon">
        <path
          d="M12.2491 5.03906L9.24915 8.03906C8.9679 8.34375 8.47571 8.34375 8.19446 8.03906C7.88977 7.75781 7.88977 7.26562 8.19446 6.98438L9.9054 5.25H1.23352C0.811646 5.25 0.483521 4.92188 0.483521 4.5C0.483521 4.05469 0.811646 3.75 1.23352 3.75H9.9054L8.19446 2.03906C7.88977 1.75781 7.88977 1.26562 8.19446 0.984375C8.47571 0.679688 8.9679 0.679688 9.24915 0.984375L12.2491 3.98438C12.5538 4.26562 12.5538 4.75781 12.2491 5.03906Z"
          fill="currentColor" />
      </symbol>
    <symbol xmlns="http://www.w3.org/2000/svg" viewBox='0 0 20 21' fill="none" id="tii-pp-clear-circle-icon">
        <path
          d='M0 10.5C0 4.99219 4.45312 0.5 10 0.5C15.5078 0.5 20 4.99219 20 10.5C20 16.0469 15.5078 20.5 10 20.5C4.45312 20.5 0 16.0469 0 10.5ZM6.83594 8.66406L8.67188 10.5L6.83594 12.3359C6.44531 12.7266 6.44531 13.3125 6.83594 13.6641C7.1875 14.0547 7.77344 14.0547 8.125 13.6641L9.96094 11.8281L11.8359 13.6641C12.1875 14.0547 12.7734 14.0547 13.125 13.6641C13.5156 13.3125 13.5156 12.7266 13.125 12.3359L11.2891 10.5L13.125 8.66406C13.5156 8.3125 13.5156 7.72656 13.125 7.33594C12.7734 6.98438 12.1875 6.98438 11.8359 7.33594L9.96094 9.21094L8.125 7.33594C7.77344 6.98438 7.1875 6.98438 6.83594 7.33594C6.44531 7.72656 6.44531 8.3125 6.83594 8.66406Z' fill="currentColor" />
      </symbol>
      <symbol viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg' id="tii-pp-question-mark-icon">
        <path
          d='M9 0.25C13.957 0.25 18 4.29297 18 9.25C18 14.2422 13.957 18.25 9 18.25C4.00781 18.25 0 14.2422 0 9.25C0 4.29297 4.00781 0.25 9 0.25ZM9 14.3125C9.59766 14.3125 10.125 13.8203 10.125 13.1875C10.125 12.5547 9.59766 12.0625 9 12.0625C8.33203 12.0625 7.875 12.5547 7.875 13.1875C7.875 13.8203 8.36719 14.3125 9 14.3125ZM11.4258 9.32031C12.1992 8.86328 12.6562 8.05469 12.6562 7.21094C12.6562 5.83984 11.5664 4.75 10.125 4.75H8.33203C6.99609 4.75 5.90625 5.83984 5.90625 7.21094C5.90625 7.66797 6.29297 8.05469 6.75 8.05469C7.20703 8.05469 7.59375 7.66797 7.59375 7.21094C7.59375 6.78906 7.91016 6.4375 8.36719 6.4375H10.1602C10.582 6.4375 10.9688 6.78906 10.9688 7.21094C10.9688 7.49219 10.8281 7.73828 10.582 7.87891L8.57812 9.07422C8.29688 9.25 8.15625 9.53125 8.15625 9.8125V10.375C8.15625 10.832 8.54297 11.2188 9 11.2188C9.45703 11.2188 9.84375 10.832 9.84375 10.375V10.3047L11.4258 9.32031Z'
          fill='#043264' />
      </symbol>
      <symbol viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg" id="tii-pp-add-traveller-icon">
        <path d="M6.51562 7.87598C4.57422 7.87598 3.01562 6.31738 3.01562 4.37598C3.01562 2.46191 4.57422 0.875977 6.51562 0.875977C8.42969 0.875977 10.0156 2.46191 10.0156 4.37598C10.0156 6.31738 8.42969 7.87598 6.51562 7.87598ZM7.88281 9.18848C10.5078 9.18848 12.6406 11.3213 12.6406 13.9463C12.6406 14.4658 12.2031 14.876 11.6836 14.876H1.32031C0.800781 14.876 0.390625 14.4658 0.390625 13.9463C0.390625 11.3213 2.49609 9.18848 5.12109 9.18848H7.88281ZM17.2344 6.34473C17.5898 6.34473 17.8906 6.64551 17.8906 7.00098C17.8906 7.38379 17.5898 7.65723 17.2344 7.65723H15.9219V8.96973C15.9219 9.35254 15.6211 9.62598 15.2656 9.62598C14.8828 9.62598 14.6094 9.35254 14.6094 8.96973V7.65723H13.2969C12.9141 7.65723 12.6406 7.38379 12.6406 7.00098C12.6406 6.64551 12.9141 6.34473 13.2969 6.34473H14.6094V5.03223C14.6094 4.67676 14.8828 4.37598 15.2656 4.37598C15.6211 4.37598 15.9219 4.67676 15.9219 5.03223V6.34473H17.2344Z" fill="currentColor"/>
        </symbol>
    </svg>
  );
};

export default CommonSvgIcon;
